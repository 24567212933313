import { ASSIGNED_SUPSCRIPTION_TYPES } from 'helpers/variables';

const {
	PACKET,
	PAYMENT_METHOD_FREE,
	PAYMENT_METHOD_VOUCHER
} = ASSIGNED_SUPSCRIPTION_TYPES;

export const getAssignedSubscriptions = (data) => {
	const allowedPaymentMethods = [PAYMENT_METHOD_FREE, PAYMENT_METHOD_VOUCHER];

	const assignedSubscriptions = data.filter(
		(product) =>
			product.type === PACKET &&
			allowedPaymentMethods.includes(product.payment_method)
	);

	return assignedSubscriptions;
};
