import { useQuery } from 'react-query';

// Import helpers
import { GET_SETTINGS_GLOBAL, QUERY_SETTINGS_ADS } from 'helpers/api';

// Import utilities
import { useSubscriptions, useAuth } from 'components/utilities';

// Import services
import services from 'services/services';

const usePlayerAds = ({ isPreview = false, isChannelPlayer = false }) => {
	const { isAuth } = useAuth();
	const { isSubscriptions, isSubscriptionsLoading } = useSubscriptions({
		isLogged: isAuth
	});

	const getAdsSettings = async () => {
		const { data } = await services.get(GET_SETTINGS_GLOBAL);
		const webLink = isChannelPlayer
			? data?.features?.vast.web_link_live
			: data?.features?.vast.web_link;
		const vodTimeIntervalAd =
			parseInt(data?.features?.vast.time_interval) * 1000 || null;

		const httpRegex = /^http/;

		return {
			adsUrl: webLink && httpRegex.test(webLink) ? webLink : null,
			vodTimeIntervalAd
		};
	};

	const { data, isLoading: isAdsSettingsLoading } = useQuery({
		queryKey: QUERY_SETTINGS_ADS,
		queryFn: getAdsSettings
	});

	const isAdsLoading = isPreview
		? isAdsSettingsLoading
		: isAdsSettingsLoading || isSubscriptionsLoading;

	const isAdsAvailable = isChannelPlayer || (!isSubscriptions && !isPreview);

	const adsSettingsUrl = isAdsAvailable ? data?.adsUrl : '';

	return {
		isAdsLoading,
		vodTimeIntervalAd: data?.vodTimeIntervalAd,
		adsSettingsUrl
	};
};

export default usePlayerAds;
