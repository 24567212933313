import React from 'react';
import { groupBy } from 'lodash';
import Cookie from 'js-cookie';
import differenceInSeconds from 'date-fns/difference_in_seconds';

// Import helpers
import { MANIFEST_MARKER } from 'helpers/variables';
import { getUserAgent } from 'helpers/index';
import {
	addMaxDurationToAdUrl,
	getManifestUri,
	getUserConfigVolume
} from 'components/context/player/helpers';

// Import variables
import {
	TRACKING_INTERVAL_TIME,
	LOCALSTORAGE_TRACKING,
	EVENTS_NAMES
} from 'helpers/variables';

const {
	PLAY,
	PAUSE,
	ERROR,
	LOADED_DATA,
	LOADED_METADATA,
	ENDED,
	TIME_UPDATE,
	BUFFERING,
	ADAPTATION,
	CAST_STATUS_CHANGED,
	FULLSCREEN_CHANGE,
	TIMELINEREGIONADDED
} = EVENTS_NAMES;

// ******************** SHAKA PLAYER COMMON HELPERS ********************

// default tv UI config options
const getUIConfiguration = () => {
	return {
		controlPanelElements: [],
		overflowMenuButtons: [],
		addBigPlayButton: false,
		doubleClickForFullscreen: false,
		addSeekBar: false,
		seekBarColors: {
			base: '#666',
			played: '#21619A',
			buffered: '#666'
		}
	};
};

// player configuration
const getPlayerConfiguration = (drm, certificate) => {
	return {
		preferredAudioLanguage: 'pl',
		preferredTextLanguage: 'pl',
		streaming: {
			alwaysStreamText: true,
			bufferBehind: 10,
			bufferingGoal: 10,
			rebufferingGoal: 5
		},
		drm: {
			servers: {
				'com.widevine.alpha': drm?.WIDEVINE || '',
				// 'com.microsoft.playready': drm.PLAYREADY,
				'com.apple.fps.1_0': drm?.FAIRPLAY?.src || ''
			},
			advanced: {
				'com.apple.fps.1_0': {
					serverCertificateUri: certificate || drm?.FAIRPLAY?.cert || ''
				},
				'com.widevine.alpha': {
					videoRobustness: 'SW_SECURE_CRYPTO',
					audioRobustness: 'SW_SECURE_CRYPTO'
				}
			}
		},
		abr: {
			enabled: true
		}
	};
};

// fetch data required to configure shaka player
export const fetchPlaylistData = async (playlist, securityType) => {
	const securityTypeParameter = securityType
		? `&preferredSecurityType=${securityType}`
		: '';

	const url = `${playlist}${securityTypeParameter}`;

	try {
		const playlistData = await fetch(url).then((res) => res.json());

		return { playlistData };
	} catch (error) {
		// do nothing, this error is empty object
	}
};

function addShakaPlayerListeners() {
	const { type, isCatchup } = this.props;
	const isVodPlayer = type === 'player_vod';

	// video events
	this.video.addEventListener(LOADED_DATA, this.onReady);

	if (!isVodPlayer) {
		this.video.addEventListener(LOADED_METADATA, this.onMetaDataLoaded);
	}

	this.videoTarget.addEventListener(PAUSE, this.onPause);
	this.videoTarget.addEventListener(PLAY, this.onPlay);

	if (isVodPlayer || isCatchup) {
		this.videoTarget.addEventListener(ENDED, this.onEnded);
	}

	if (isVodPlayer) {
		this.videoTarget.addEventListener(TIME_UPDATE, this.onTimeUpdate);
	}

	// player events
	this.playerTarget.addEventListener(ERROR, this.onErrorEvent);
	this.playerTarget.addEventListener(BUFFERING, this.onBufferStateChange);
	this.playerTarget.addEventListener(ADAPTATION, this.onAutoQualityChange);
	this.castProxy.addEventListener(
		CAST_STATUS_CHANGED,
		this.onCastStatusChanged
	);

	// document events
	document.addEventListener(FULLSCREEN_CHANGE, this.onFullScreenChange);
}

export function removeShakaPlayerListeners() {
	const { type, isCatchup } = this.props;
	const isVodPlayer = type === 'player_vod';

	// video events
	this.video.removeEventListener(LOADED_DATA, this.onReady);
	this.videoTarget.removeEventListener(PAUSE, this.onPause);
	this.videoTarget.removeEventListener(PLAY, this.onPlay);

	if (!isVodPlayer) {
		this.video.removeEventListener(LOADED_METADATA, this.onMetaDataLoaded);
	}

	if (isVodPlayer || isCatchup) {
		this.videoTarget.removeEventListener(ENDED, this.onEnded);
	}

	if (isVodPlayer) {
		this.videoTarget.removeEventListener(TIME_UPDATE, this.onTimeUpdate);
	}

	// player events
	this.playerTarget.removeEventListener(ERROR, this.onErrorEvent);
	this.playerTarget.removeEventListener(BUFFERING, this.onBufferStateChange);
	this.playerTarget.removeEventListener(ADAPTATION, this.onAutoQualityChange);
	this.castProxy.removeEventListener(
		CAST_STATUS_CHANGED,
		this.onCastStatusChanged
	);

	// document events
	document.removeEventListener(FULLSCREEN_CHANGE, this.onFullScreenChange);
}

export function playerInitialization(params) {
	const [shaka, playlistData, adsSettingsUrl, vodTimeIntervalAd] = params;

	const {
		playlistData: {
			drm,
			sources: { HLS, DASH },
			subtitles: movieSubtitles,
			use_watermark: useWatermark
		},
		certificate
	} = playlistData;

	const {
		configuration: { seekAvailable },
		type,
		isSafari
	} = this.props;

	const isVodPlayer = type === 'player_vod';

	if (isVodPlayer) {
		this.setState({ movieSubtitles });
	} else {
		this.setState({ seekAvailable });
	}

	// Link to manifest
	const manifestUri = getManifestUri({ isSafari, hls: HLS, dash: DASH });

	//Initialize shaka player
	this.player = new shaka.Player(this.video);

	// Initialize cast
	this.castProxy = new shaka.cast.CastProxy(
		this.video,
		this.player,
		process.env.REACT_APP_CAST_RECEIVER_ID
	);

	this.playerTarget = this.castProxy.getPlayer();
	this.videoTarget = this.castProxy.getVideo();

	// Setting up shaka player UI
	const ui = new shaka.ui.Overlay(this.player, this.videoContainer, this.video);

	// configure default UI
	const uiConfig = getUIConfiguration(isVodPlayer);
	ui.configure(uiConfig);
	ui.getControls();

	// player configure
	const playerConfig = getPlayerConfiguration(drm, certificate);

	this.playerTarget.configure(playerConfig);

	const restoreChannelProperties = () => {
		// Set player volume
		const { volume, isMuted: isUserConfigMuted } = getUserConfigVolume();

		this.video.muted = isUserConfigMuted;
		this.video.currentTime = this.player.seekRange().end;

		this.setVolume(volume, isUserConfigMuted);

		this.setState({ isAdVisible: false });
	};

	const restoreVodProperties = () => {
		const { continueWatchingTime } = this.props;
		const { isNextAd } = this.state;

		if (!isNextAd && !continueWatchingTime) {
			this.videoTarget.currentTime = 0;
		}

		// Set player volume
		const { volume, isMuted: isUserConfigMuted } = getUserConfigVolume();

		this.video.muted = isUserConfigMuted;

		this.setVolume(volume, isUserConfigMuted);

		this.setState({ isAdVisible: false });

		vodTimeIntervalAd && setNextVodAdsTimeout();
	};

	const playerAdsInit = (duration = null) => {
		const adManager = this.playerTarget.getAdManager();

		const container = ui.getControls().getClientSideAdContainer();

		adManager.initClientSide(container, this.video);

		// eslint-disable-next-line no-undef
		const adsRequest = new google.ima.AdsRequest();

		adsRequest.adTagUrl = duration
			? addMaxDurationToAdUrl(adsSettingsUrl, duration)
			: adsSettingsUrl;

		adManager.requestClientSideAds(adsRequest);

		adManager.addEventListener(shaka.ads.AdManager.AD_STARTED, (e) => {
			if (e.originalEvent.type === 'start') {
				this.video.muted = true;
				this.setState({ isAdVisible: true });
			}
		});

		adManager.addEventListener(shaka.ads.AdManager.AD_COMPLETE, () =>
			isVodPlayer ? restoreVodProperties() : restoreChannelProperties()
		);

		adManager.addEventListener(shaka.ads.AdManager.AD_SKIPPED, () =>
			isVodPlayer ? restoreVodProperties() : restoreChannelProperties()
		);
	};

	if (!isVodPlayer) {
		// THIS EVENT WORKS ONLY ON DASH
		this.playerTarget.addEventListener(TIMELINEREGIONADDED, (e) => {
			if (e.detail?.schemeIdUri?.includes(':scte35:')) {
				if (e.detail?.startTime === e.detail?.endTime) {
					return;
				}

				const duration =
					parseInt(e.detail?.endTime - e.detail?.startTime) * 1000; // ms

				adsSettingsUrl && playerAdsInit(duration);
			}
		});
	}

	const setNextVodAdsTimeout = () => {
		if (this.nextVodAdsTimeout) {
			clearTimeout(this.nextVodAdsTimeout);
		}

		this.nextVodAdsTimeout = setTimeout(() => {
			const { isAdVisible } = this.state;

			if (!isAdVisible && adsSettingsUrl) {
				playerAdsInit();
			}
			this.setState({ isNextAd: true });

			this.nextVodAdsTimeout = null;
		}, vodTimeIntervalAd);
	};
	// Try to asynchronous manifest load
	this.playerTarget
		.load(manifestUri)
		.then(() => {
			this.streamStartDate = new Date();

			getPlayerResources.call(this);
			playerTrackingInit.call(this, { manifestUri });
			this.trackingInterval = setInterval(
				() => sendPlayerEvents.call(this),
				TRACKING_INTERVAL_TIME
			);

			adsSettingsUrl && isVodPlayer && playerAdsInit();

			if (adsSettingsUrl && isSafari && !isVodPlayer) {
				listenHLSManifest(playerAdsInit);
			}
		})
		.catch(this.onError);

	// Set buffering
	this._isMounted && this.setState({ buffering: true, useWatermark });

	// create event listeners
	addShakaPlayerListeners.call(this);
}

export function setSupportError() {
	const error = { code: 'BROWSER_NOT_SUPPORTED' };
	this.onError(error);
	this._isMounted && this.setState({ buffering: false, isReady: false });
}

// player tracking initialization
function playerTrackingInit({ manifestUri }) {
	const { duration } = this.state;
	const {
		isSafari,
		configuration: {
			tracking: {
				collector,
				sessionId,
				customerId,
				subscriberId,
				productId,
				customData: { productType, materialId, login, main, accountId }
			}
		},
		type: playerType,
		isCatchup
	} = this.props;

	const {
		browser: { name: browserName, version: browserVersion },
		os: { name: osName, version: osVersion }
	} = getUserAgent();
	const userId = Cookie.get('uid');

	const isVodPlayer = playerType === 'player_vod';
	const videoDuration = isVodPlayer || isCatchup ? Math.ceil(duration) : -1;

	const urlData = {
		basicUrl: `https:${collector}init.gif?`,
		deviceId: `deviceId=${userId}&`,
		version: `version=V1&`,
		sessionId: `sessionId=${sessionId}&`,
		customerId: `customerId=${customerId}&`,
		subscriberId: `subscriberId=${subscriberId}&`,
		terminal: `terminal=PC&`,
		mimeType: `mimeType=${isSafari ? 'HLS' : 'DASH'}&`,
		productId: `productId=${productId}&`,
		manifestUrl: `url=${encodeURIComponent(manifestUri)}&`,
		duration: `duration=${videoDuration}&`,
		agent: `agent=${browserName}&`,
		agentVersion: `agentVersion=${browserVersion}&`,
		os: `os=${encodeURIComponent(osName)}&`,
		osVersion: `osVersion=${osVersion}&`,
		maker: `maker=${browserName}&`,
		rendererType: `rendererType=HTML5&`,
		playerVersion: `playerVersion=ShakaPlayer_3.2.1&`,
		referrer: `referrer=${encodeURIComponent(window.location.href)}&`,
		platform: `platform=BROWSER&`,
		productType: `productType=${productType}&`,
		materialId: `materialId=${materialId}&`,
		login: `login=${login}&`,
		main: `main=${main}&`,
		accountId: `accountId=${accountId}`
	};

	const imageSrc = getTrackingUrl({ urlData });

	// send tracking init request
	const image = new Image();
	image.src = imageSrc;
}

// send events data for tracking
export function sendPlayerEvents() {
	const timeDiff = differenceInSeconds(new Date(), this.streamStartDate);
	const sessionDuration = this.streamStartDate ? timeDiff : 0;

	const trackingStorage = JSON.parse(
		localStorage.getItem(LOCALSTORAGE_TRACKING)
	);

	const {
		configuration: {
			tracking: { collector, sessionId, customerId }
		}
	} = this.props;

	const urlData = {
		basicUrl: `https:${collector}events.gif?`,
		sessionId: `sessionId=${sessionId}&`,
		customerId: `customerId=${customerId}&`,
		sessionDuration: `sessionDuration=${sessionDuration}&`,
		events: 'events='
	};

	let imageSrc = getTrackingUrl({ urlData });

	const isTrackingDataExist = trackingStorage?.events;

	const events = isTrackingDataExist
		? trackingStorage.events.map(
				({ type, sessionOffset, trackOffset, bitrate }) =>
					`${type},${sessionOffset},${trackOffset},${bitrate}`
		  )
		: [];

	const eventsString = events.join(';');
	imageSrc += eventsString;

	const image = new Image();
	// clear tracking storage on send success
	image.onload = () => localStorage.removeItem(LOCALSTORAGE_TRACKING);
	// sava tracking data on send error
	image.onerror = () => {
		localStorage.setItem(
			LOCALSTORAGE_TRACKING,
			JSON.stringify({
				...trackingStorage,
				url: imageSrc
			})
		);
	};
	// send tracking events request
	image.src = imageSrc;
}

// get tracking url
const getTrackingUrl = ({ urlData }) => Object.values(urlData).join('');

// send previous session tracking data
export const sendPreviousSessionTracking = () => {
	const isOnline = navigator.onLine;
	const trackingStorage = JSON.parse(
		localStorage.getItem(LOCALSTORAGE_TRACKING)
	);

	if (trackingStorage?.url && isOnline) {
		const image = new Image();
		image.src = trackingStorage.url;
		localStorage.removeItem(LOCALSTORAGE_TRACKING);
	}
};

// set tracking event data to state
export function setTrackingEvent(eventName) {
	const trackingStorage = JSON.parse(
		localStorage.getItem(LOCALSTORAGE_TRACKING)
	);

	// create event object
	const event = {
		name: eventName,
		bitrate: getCurrentBitrate.call(this),
		sessionOffset: getTimeSinceStreamStart.call(this),
		trackOffset: Math.ceil(this.videoTarget.currentTime),
		type: TRACKING_EVENT_INDEX[eventName]
	};

	const events = trackingStorage?.events
		? [...trackingStorage.events, event]
		: [event];

	localStorage.setItem(
		LOCALSTORAGE_TRACKING,
		JSON.stringify({
			...trackingStorage,
			events
		})
	);
}

// get difference between stream start time and current time in seconds
function getTimeSinceStreamStart() {
	const timeDiff = differenceInSeconds(new Date(), this.streamStartDate);
	const sessionDuration = this.streamStartDate ? timeDiff : 0;
	return sessionDuration;
}

// get current stream bitrate
function getCurrentBitrate() {
	const tracks = this.playerTarget.getVariantTracks();
	const currentTrack = tracks.find(({ active }) => active);
	const currentBitrate = currentTrack ? currentTrack.videoBandwidth / 1000 : 0;
	return currentBitrate;
}

// tracking events index
export const TRACKING_EVENT_INDEX = {
	PLAYING: 0,
	BUFFERING: 1,
	PAUSED: 2,
	SEEKING: 3,
	TRACK_CHANGED: 4,
	COMPLETE: 5,
	STOPPED: 6,
	CLOSED: 7,
	ERROR: 8
};

const getVideoVariants = ({ player }) => {
	const languages = player.getAudioLanguages();

	const videoVariants = player
		.getVariantTracks()
		.sort((a, b) => b.height - a.height);
	const profiles = groupBy(videoVariants, 'language');

	const switchHistory = player.getStats().switchHistory;
	const initVariant = switchHistory.find(({ type }) => type === 'variant');
	const selectedLanguage = videoVariants.find(({ id }) => id === initVariant.id)
		.language;

	return { languages, profiles, selectedLanguage };
};

// ******************** SHAKA PLAYER VOD HELPERS ********************

// get subtitles, audio and video variants
function getPlayerResources() {
	const { isSafari } = this.props;

	if (!isSafari) {
		const movieSubtitles = this.state.movieSubtitles;

		if (movieSubtitles) {
			movieSubtitles.forEach(({ language: lang, url }) => {
				this.player.addTextTrack(url, lang, 'subtitle', 'text/vtt', '', lang);
			});

			const { languages, profiles, selectedLanguage } = getVideoVariants({
				player: this.playerTarget
			});

			this.setState({ languages, profiles, selectedLanguage });
		}
	}

	const subtitles = this.playerTarget.getTextTracks();
	this.setState({ subtitles, duration: this.video.duration });
}

export function renderSubtitlesForSafari() {
	const { movieSubtitles } = this.state;
	return movieSubtitles.map(({ id, language, url }) => (
		<track
			key={id}
			label="Subtitles"
			kind="subtitles"
			srcLang={language}
			src={url}
			default=""
		/>
	));
}

export const listenHLSManifest = (initAds) => {
	let counter = 0;

	if (navigator.serviceWorker.controller) {
		navigator.serviceWorker.addEventListener('message', async (event) => {
			const currentClient =
				event.data.clientId === navigator.serviceWorker.controller.id;
			const isManifestFetched = event.data.type === 'MANIFEST_FETCHED';

			if (currentClient && isManifestFetched) {
				try {
					const eventUrl = event.data.url + MANIFEST_MARKER;
					const data = await fetch(eventUrl, { mode: 'no-cors' });
					const text = await data.text();

					console.log('MANIFEST_FETCHED');

					if (text.includes('#EXT-X-CUE-OUT')) {
						const match = text.match(/PLANNED-DURATION=(\d+)/);
						const plannedDuration = match ? parseInt(match[1], 10) : null;

						console.error('EXT-X-CUE-OUT', counter);
						console.log({ plannedDuration });

						counter += 1;

						if (counter === 1) {
							console.error('INIT ADS');
							initAds();
						}
					}

					if (text.includes('#EXT-X-CUE-IN')) {
						counter = 0;
					}
				} catch (error) {}
			}
		});
	}
};
